import * as actionTypes from './actionTypes';
import history from '../../features/routing/history';
import { makeRequest } from '../../features/communication/request';
import { useContext } from 'react';
import { BaseLayoutContext } from 'layouts/baseLayout/BaseLayoutProvider';
import { useEffect } from 'react';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userRole) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    payload: {
      token,
      userRole,
    },
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    payload: {
      error: error,
    },
  };
};

export const logout = () => {
  localStorage.removeItem('code');
  sessionStorage.removeItem('token');
  localStorage.removeItem('userRole');
  if (history.location.pathname !== '/login') {
    localStorage.setItem('path', history.location.pathname + history.location.search);
    setTimeout(() => {
      history.push('/login');
      window.location.reload();
    }, 100);
  }
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};


export const authOFISLogin = (code) => {
  return (dispatch) => {
    dispatch(authStart());

    makeRequest({
      hostname: process.env.REACT_APP_BACKEND_API,
      method: 'post',
      url: 'Authenticate',
      body: {
        code:code
      }
    }).then((response) => {
      //debugger;
      const userRole = response.data.UserRole;
      const token = response.data.token;
      localStorage.removeItem('code');
      sessionStorage.setItem('token', token);
      localStorage.setItem('userRole', userRole);
      dispatch(authSuccess(code, userRole));
      dispatch(checkAuthTimeout(3600)); // automatic log out after one hour

      history.push(localStorage.getItem('path'));
    });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = sessionStorage.getItem('token');
    const userRole = localStorage.getItem('userRole');
    if (!token) {
      dispatch(logout());
    } else {
      dispatch(authSuccess(token, userRole));
    }
  };
};



export default function useAdmin(user) {

  const {setUserRole} = useContext(BaseLayoutContext)

  useEffect(() => {
    if(user)
    setUserRole(user)
  },[user])

  
}

