import React from 'react';

const PrivacyContent = () => (
    <>
        <h6>Introduction</h6>
        <p>
            This privacy statement describes why and how Vialto Partners, its affiliates and other entities in the Vialto professional network ("Vialto Partners", "we", "us", or "our") refers to the entity identified at <a href="https://vialto.com/about">vialto.com/about</a>) collects and uses personal data in connection with My Trips Plus (“application”), a system that automates downstream processing of EU Posted Workers Directive registrations and global (worldwide) Social Security filings and provides information about your rights as a user of the application (“you”, “user” or “individual”). Each of Vialto UK, such affiliates and other entities may be referred to in this privacy statement as a “Vialto Entity.” <br/><br/>  

            Personal data is any information relating to an identified or identifiable living person. This privacy statement applies to personal data provided to us, both by you or by others and any personal data created in connection with your use of the application. We may use personal data provided to us for any of the purposes described in this privacy statement or as otherwise stated at the point of collection.  <br/><br/>

            When collecting and using personal data, our policy is to be transparent about why and how we process personal data. To find out more about our specific processing activities in connection with the application, please go to the relevant sections of this statement.
        </p>
        <h6>Security</h6>
        <p>
            We adhere to internationally recognised security standards. Our information security management system relating to client confidential data is independently certified as complying with the requirements of ISO/IEC 27001: 2013. We have a framework of policies, procedures and training in place covering data protection, confidentiality and security and regularly review the appropriateness of the measures we have in place to keep the data we hold secure.
        </p>
        <h6>Changes to this privacy statement</h6>
        <p>
            We recognise that transparency is an ongoing responsibility so we will keep this privacy statement under regular review. <br/><br/>

            This privacy statement was last updated on the date identified at: <a href="https://vialto.com/about">vialto.com/about</a>
        </p>
        <h6>Data controller and contact information</h6>
        <p>
            The entity that is data controller for this purpose is identified at <a href="https://vialto.com/privacy">vialto.com/privacy</a> <br/><br/>

            If you have any questions about this privacy statement or how and why we process personal data, please contact us using the information available at <a href="https://vialto.com/about">vialto.com/about</a>.  
        </p>
        <h6>Collection of personal data</h6>
        <p>           
            We will collect personal data in connection with the application in a “traveller profile,” the content of which is as described below. <br/><br/>

            Personal data provided directly by you: <br/>
            <ul>
                <li>Addresses, country details and/or other location data relating to your business trips</li>
                <li>Identifiers i.e. social security number, passport number, tax ID, citizen ID, state ID </li>
                <li>Driver’s license number, car registration/license-plate</li>
                <li>Date of birth</li>
                <li>Telephone numbers</li>
                <li>Gender</li>
                <li>Employment information, job position and workplace details</li>
                <li>Education information</li>
                <li>Financial information</li>
            </ul>
            <br/>
            Personal data inferred or derived from your use of the application: <br/>
            <ul>
                <li>IP address</li>
                <li>Cookie IDs</li>
                <li>Posted Workers Directive filing IDs and/or social security filing IDs</li>
                <li>Our cookies statement is available at the foot of My Trips Plus webpages</li>
            </ul>
            <br/>
            Personal data obtained from third party sources<br/>
            The following information may be obtained from your employer where otherwise not provided by yourself: <br/>
            <ul>
                <li>Name, email address</li>
                <li>Addresses, country details and/or other location data relating to your business trips</li>
                <li>Identifiers i.e. social security number, passport number, tax ID, citizen ID, state ID </li>
                <li>Driver’s license number, car registration/license-plate</li>
                <li>Date of birth</li>
                <li>Telephone numbers</li>
                <li>Gender</li>
                <li>Employment information, job position and workplace details</li>
                <li>Education information</li>
                <li>Financial information</li>
            </ul>
        </p>
        <h6>Use​ ​of​ ​personal​ data</h6>
        <p>
            We use personal data for the following purposes: <br/>
            <ul>
                <li>
                    <strong>To provide services</strong><br/>
                    We use your personal data to perform your compliance submissions through the use of automated processes on your or your employer’s behalf. We also use your traveller profile to provide you with a personalised service tailored to your individual preferences.
                </li>
                <li>
                    <strong>To provide additional services</strong><br/>
                    We may use personal data that you provide to us to interact with you via Vialto chatbots and provide further additional services, where your employer has requested these services/features.
                </li>
                <li>
                    <strong>To maintain security</strong><br/>
                    This includes authenticating your identity and authorise access to My Trips Plus (including preventing unauthorised access) and for other security-related purposes, including system monitoring
                </li>
                <li>
                    <strong>To operate, administer, manage and improve the website and application</strong><br/>
                    Administering the website and application, troubleshooting issues with the services and identifying areas for improvement.
                </li>
                <li>
                    <strong>To maintain our administrative or client relationship management systems</strong><br/>
                    Where you are a business contact of ours, we will: Include your business contact details on our administrative and/or client relationship management systems, contact you in relation to the services and we may send you other material relevant to your interests where we are permitted to do so.        
                </li>
                <li>
                    <strong>To analyse how the website, application and associated services perform</strong><br/>
                    We may analyse how the website, application and associated services perform by reviewing the user data we capture and asking for and responding to feedback from you.
                </li>
                <li>
                    <strong>Providing you with information about us and our range of services</strong><br/>
                    Where you are a business contact of ours, we will contact you in relation to the services and we may send you other material relevant to your interests where we are permitted to do so.
                </li>
            </ul>
           Our lawful basis for all of our processing in connection with My Trips Plus and associated services is our legitimate interest to provide the services in a secure and efficient way to you and your employer and to operate, develop and improve our business.
        </p>
        <h6>When and how we share personal data and locations of processing</h6>
        <p>
        We will only share personal data with others when we are legally permitted to do so. When we share data with others, we put contractual arrangements and security mechanisms in place to protect the data and to comply with our data protection, confidentiality and security standards. <br/><br/>
        We share personal data processed in connection with the application as follows: <br/><br/>
        <ul>
            <li>
                <strong>Other Vialto member firms</strong><br/>
                We share personal data processed in connection with the application with the following Vialto member firms:<br/><br/>
                <ul>
                    <li>o	Vialto member firms in the UK (including Northern Ireland), Poland, Australia, Canada and the United States are primarily involved in providing the services. We may transfer your personal information to other Vialto member firms where your organisation engages such firms to provide tax or immigration services. For details of our member firm locations, please click <a href="https://vialto.com/about">here</a>. This list may change from time to time in-line without notice. Per our contract with your employer, this sharing is necessary for administrative purposes, to provide advice from Vialto member firms in different territories and to deliver EU Posted Workers Directive and global social security related services in relation to your/your employer’s business activities in those territories.</li>
                    <li>Our business contacts are visible to and used by Vialto users from other Vialto member firms to learn more about a contact, client or opportunity they have an interest in. Please see our website privacy statement for further information. For details of our member firm locations, please click <a href="https://vialto.com/about">here</a>. We have the European Commission approved standard contractual clauses in place with the other Vialto member firms to provide appropriate safeguards for personal data transferred outside of the EU.  The standard contractual clauses are available <a rel="noreferrer" target="_blank" href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">here</a>. 
                    </li>
                </ul>
                <br/>
            </li>
            <li>
                <strong>Third party organisations that provide applications/functionality, data processing or IT services to us</strong><br/>
                We are part of a global network of firms and in common with other professional service providers, we use third parties to help us run our business and these third parties may be located in other countries. Please see our website privacy statement for further information. <br/><br/>

                Where the locations of processing are outside of the EU, we have the European Commission approved standard contractual clauses in place to provide appropriate safeguards for personal data transferred outside of the EU.  The standard contractual clauses are available <a rel="noreferrer" target="_blank" href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">here</a>. 
            </li>
            <br/>
            <li>
                <strong>Law enforcement or other government and regulatory agencies or to other third parties as required by, and in accordance with, applicable law or regulation</strong><br/><br/>
                Per our contract with your employer, sharing with government agencies within the EU and globally is necessary for: <br/>
                <ul>
                    <li>
                    Administrative purposes,
                    </li>
                    <li>
                    To provide advice from Vialto member firms in different territories and 
                    </li>
                    <li>
                    To deliver EU Posted Workers Directive and global social security related services in relation to your/your employer’s business activities in those territories.
                    </li>
                </ul>
                <br/>
                Occasionally, we may receive requests from third parties with authority to obtain disclosure of personal data, such as to check that we are complying with applicable law and regulation, to investigate an alleged crime, to establish, exercise or defend legal rights.  We will only fulfil requests for personal data where we are permitted to do so in accordance with applicable law or regulation. 
            </li>
        </ul>
        </p>
        <h6>Data retention</h6>
        <p>
        We retain personal data processed in connection with the application as follows: <br/>
        <ul>
            <li>Throughout the lifetime of our contract with your employer and for a further seven years from the termination date of our contract with your employer</li>
        </ul>
        </p>
        <h6>Individuals’ rights and how to exercise them</h6>
        <p>
        You have certain rights over your personal data.  Data controllers are responsible for fulfilling these rights.  Where we decide how and why personal data is processed, we are a data controller.<br/><br/>
        Individuals’ rights are the right of access to personal data, to rectification of personal data, to erasure of personal data / right to be forgotten, to restrict processing of personal data, to object to processing of personal data, to data portability, the right to withdraw consent at any time (where processing is based on consent) and the right to lodge a complaint with a supervisory authority. <br/><br/>
        Please see further information about these rights and how to exercise them below.
        </p>
        <h6>Right of access</h6>
        <p>
        You have the right to obtain confirmation as to whether we process personal data about you, receive a copy of your personal data held by us as a data controller and obtain certain other information about how and why we process your personal data (similar to the information provided in this privacy statement).  <br/><br/>
        You may exercise this right by emailing us at <strong>privacy@vialto.com</strong>. We will aim to respond to any requests for information promptly, and in any event within the legally required time limits. 
        </p>
        <h6>Right to rectification</h6>
        <p>
        You have the right to request for your personal data to be amended or rectified where it is inaccurate (for example, if you change your name or address) and to have incomplete personal data completed.<br/><br/>
        To update personal data submitted to us, please email us at <strong>privacy@vialto.com</strong> . When practically possible, once we are informed that any personal data processed by us is no longer accurate, we will make updates, as appropriate, based on your updated information.
        </p>
        <h6>The right to erasure / right to be forgotten</h6>
        <p>
        You have the right to obtain deletion of your personal data in the following cases:<br/>
        <ul>
            <li>the personal data are no longer necessary in relation to the purposes for which they were collected and processed</li>
            <li>our lawful basis for processing is consent, you withdraw consent and we have no other lawful basis for the processing</li>
            <li>our lawful basis for processing is that the processing is necessary for a legitimate interest pursued by us, you object to our processing and we do not have overriding legitimate grounds</li>
            <li>you object to our processing for direct marketing purposes</li>
            <li>your personal data have been unlawfully processed and</li>
            <li>your personal data must be erased to comply with a legal obligation to which we are subject</li>
        </ul>
        To request deletion of your personal data, please email us at <strong>privacy@vialto.com</strong>.
        </p>
        <h6>The right to restrict processing</h6>
        <p>
        You have the right to restrict our processing of your personal data in the following cases:<br/>
        <ul>
            <li>for a period enabling us to verify the accuracy of the personal data where you have contested the accuracy of the personal data </li>
            <li>your personal data have been unlawfully processed and you request restriction of processing instead of deletion</li>
            <li>the personal data are no longer necessary in relation to the purposes for which they were collected and processed but the personal data are required by you to establish, exercise or defend legal claims and</li>
            <li>for a period enabling us to verify whether the legitimate grounds relied on by us override your interests (where you have objected to processing based on it being necessary for the pursuit of a legitimate interest identified by us). </li>

        </ul>
        To restrict our processing of your personal data, please email us at <strong>privacy@vialto.com</strong>.
        </p>
        <h6>The right to object to processing</h6>
        <p>
        You have the right to object to our processing of your personal data in the following cases:<br/>
        <ul>
            <li>our lawful basis for processing is that the processing is necessary for a legitimate interest pursued by us and</li>
            <li>our processing for direct marketing purposes.</li>
        </ul>
        To object to our processing of your personal data, please email us at <strong>privacy@vialto.com</strong>.
        </p>
        <h6>Right to data portability</h6>
        <p>
        You have a right to receive your personal data provided to us and have the right to send the data to another organisation (or ask us to do so if technically feasible) where our lawful basis for processing the personal data is consent or necessity for the performance of our contract with you and the processing is carried out by automated means.<br/><br/>
        To exercise your right to data portability, please email us at <strong>privacy@vialto.com</strong>.
        </p>
        <h6>Complaints</h6>
        <p>
        We hope that you won’t ever need to, but if you do want to complain about our use of personal data, please send an email with the details of your complaint to <strong>privacy@vialto.com</strong>.  We will look into and respond to any complaints we receive. <br/><br/>
        
You also have the right to lodge a complaint with the supervisory authority in your country of residence, place of work or the country in which an alleged infringement of data protection law has occurred within the EU.  The Information Commissioner's Office (“ICO”) is the UK data protection regulator/supervisory authority. For further information on your rights and how to complain to the ICO, please refer to the <a rel="noreferrer" target="_blank" href="https://ico.org.uk/your-data-matters/raising-concerns/">ICO website</a>.   
        </p>

    </>
);

export default PrivacyContent;
