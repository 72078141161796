import { Modal, Row, Col, Input, message, Tooltip } from 'antd';
import { MdOutlineClear } from 'react-icons/md';
import { makeRequest } from 'features/communication/request';
import { BaseLayoutContext } from 'layouts/baseLayout/BaseLayoutProvider';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

const ManageDelegationModal = ({ personalAssistant, segmentId }) => {
  const { isSettingsWindowOpen, changeSettingsWindowVisibility } = useContext(BaseLayoutContext);
  const [inputValue, setInputValue] = useState(personalAssistant);

  const handleCancel = () => {
    changeSettingsWindowVisibility();
  };

  const handleOk = () => {
    makeRequest({
      hostname: process.env.REACT_APP_BACKEND_API,
      method: 'post',
      url: `personalassistant/${segmentId}`,
      body: { email: inputValue },
    })
      .then(() => {
        message.success('Trip is correctly delegated ');

        setTimeout(() => {
          changeSettingsWindowVisibility();
          window.location.reload();
        }, 500);
      })
      .catch(() => message.error('Something went wrong'));
  };

  useEffect(() => {
    setInputValue(personalAssistant);
  }, [personalAssistant]);

  return (
    <Modal
      width={650}
      title="Delegation"
      visible={isSettingsWindowOpen}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <Title>Manage delegation for this myTrips Plus questionnaire</Title>
        </Col>

        <Col span={12}>Your preset myTrips+ delegate</Col>
        <Col span={12}>{personalAssistant}</Col>

        <Col span={12}>Delegate this trip to</Col>
        <Col span={10}>
          <Input size="small" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        </Col>
        <Col span={2}>
          <Tooltip
            color="white"
            overlayInnerStyle={{ color: 'black' }}
            title="Click here to clear the delegate"
          >
            <MdOutlineClear style={{ cursor: 'pointer' }} onClick={() => setInputValue('')} />
          </Tooltip>
        </Col>
      </Row>
    </Modal>
  );
};

export default ManageDelegationModal;

const Title = styled.div`
  color: black;
  font-weight: bold;
`;
