import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

export const Button = styled(AntdButton)`
  grid-column: 2/4;
  justify-self: end;
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: end;
  gap: 16px;
  max-width: 50%;
  margin: 32px auto 0 auto;
`;

export const Result = styled.p`
  margin: 24px 0;
  justify-self: center;
  grid-column: 1/4;
`;
