import React from 'react';
import styled from 'styled-components';
import { DatePicker } from 'antd';

const InputWithLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.p`
  font-weight: bold;
  font-size: 11px;
  margin: 2px;
  text-align: left;
  font-family: 'Aeonik-Regular';
`;

export const DateFilter = ({ label, format, state, setState, disabled, error,removeError }) => (
  <InputWithLabelWrapper>
    <Label className='required'>{label}</Label>
    <DatePicker format={format} value={state} onChange={setState} disabled={disabled} onClick={() => removeError(label)} />
    {error && error.map((field) => {
        if(field.error === label){
          return <p className='error_msg_uploadgfile'>{field.message}</p>
        }
      })}
  </InputWithLabelWrapper>
);

export default DateFilter;
