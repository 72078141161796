import React, { Component } from 'react';
import BaseLayout from '../layouts/baseLayout/baseLayout';
import Kibana from '../components/reporting/kibana';

class KibanaPage extends Component {
    render(){
        return (
            <BaseLayout>
                <Kibana {...this.props}/>           
            </BaseLayout>
        );
    }
}

export default KibanaPage;
