import React, { Component } from 'react';
import BaseLayout from '../layouts/baseLayout/baseLayout';
import MyTripsForm from '../components/imigrationv1/MyTripsForm';

class MyTrips extends Component{

  render(){
    return (
        <BaseLayout>
            <MyTripsForm {...this.props} /> 
        </BaseLayout>
    );
  }
}

export default MyTrips;