
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const IdleTimeOutModal = ({showModal, handleClose, handleLogout, remainingTime}) => {

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>You Have Been Idle!</Modal.Title>
            </Modal.Header>
            <Modal.Body>You Will Get Timed Out. You want to stay?</Modal.Body>
            <Modal.Footer>
            <Button /* variant="danger" */ onClick={handleLogout} style={{backgroundColor:'#F24D54', borderColor:'#F24D54', borderRadius:'24px', padding: '6px 24px'}}>
                Logout
            </Button>
            <Button variant="primary" onClick={handleClose} style={{backgroundColor:'#00B649', borderColor:'#00B649', borderRadius:'24px', padding: '6px 24px'}}>
                Stay
            </Button>
            </Modal.Footer> 
        </Modal>
    )
}