import React, { Component } from 'react';
import BaseLayout from '../layouts/baseLayout/baseLayout';
import ChatBotComponent from '../components/chatbot/chatbotWindow/chatbotWindow';

class AppChatbot extends Component{
  render(){
  return (
    <BaseLayout>
      <ChatBotComponent />
    </BaseLayout>
  );
}}

export default AppChatbot;
