import styled from 'styled-components';
import { Input, DatePicker, Button, Select } from 'antd';

const InputWithLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 10vw;
  @media only screen and (min-width: 360px) and (max-width: 768px) {
    width: 100%;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
  }
`;

export const Label = styled.p`
  font-weight: bold;
  font-size: 11px;
  margin: 2px;
  text-align: left;
  font-family: 'Aeonik-Regular';
`;

export const FiltersBackground = styled.div`
  background-color: #F7F5F5;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 5px;
  margin-bottom: 2px;
  @media only screen and (min-width: 360px) and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 0.96fr));
    grid-gap: 5px;
  }
  
  
`;

export const FilterButton = styled(Button)`
  margin: 15px 0 0 15px;
`;

export const InputFilter = ({ label, type, state, setState, disabled }) => {
  const handleChange = (event) => setState(event.target.value);

  return (
    <InputWithLabelWrapper>
      <Label>{label}</Label>
      <Input type={type} value={state} onChange={handleChange} disabled={disabled} />
    </InputWithLabelWrapper>
  );
};

export const SelectFilter = ({ label, state, setState, options, disabled }) => {
  const handleChange = (value) => setState(value);

  return (
    <InputWithLabelWrapper>
      <Label>{label}</Label>
      <Select
        showSearch
        value={state}
        mode="multiple"
        defaultValue={''}
        disabled={disabled}
        onChange={handleChange}
        maxTagCount="responsive"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
        }
      >
        {options &&
          options.map((value) => {
            return (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            );
          })}
      </Select>
    </InputWithLabelWrapper>
  );
};

export const DateFilter = ({ label, format, state, setState, disabled }) => (
  <InputWithLabelWrapper>
    <Label>{label}</Label>
    <DatePicker format={format} value={state} onChange={setState} disabled={disabled} />
  </InputWithLabelWrapper>
);
