import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const urlPrefix = (state, action) => {
  return updateObject(state, {
    prefix: action.payload.prefix,
  });
};

export default function reducer(state = { prefix: '/gp' }, action) {
  if (action.type === actionTypes.URL_PREFIX) {
    return urlPrefix(state, action);
  }
  return state;
}
