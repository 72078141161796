import React, { Component } from 'react';
import BaseLayout from '../layouts/baseLayout/baseLayout';
import MyNifi from '../components/nifi/nifi';

class NifiPage extends Component {
    render(){
        return (
            <BaseLayout>
                <MyNifi {...this.props}/>
            </BaseLayout>
        );
    }
}

export default NifiPage;
