import { useState } from 'react';

export const useFiltersState = () => {
  const [pnrFilter, setPnrFilter] = useState();
  const [documentTypeFilter, setDocumentTypeFilter] = useState([]);
  const [destinationCountryFilter, setDestinationCountryFilter] = useState([]);
  const [departureDateFilter, setDepartureDateFilter] = useState();
  const [tripIdFilter, setTripIdFilter] = useState();

  const clearFilters = () => {
    setPnrFilter('');
    setDocumentTypeFilter([]);
    setDestinationCountryFilter([]);
    setDepartureDateFilter();
    setTripIdFilter('');
  };

  return {
    pnr: {
      state: pnrFilter,
      setState: setPnrFilter,
    },
    obligation_and_document_type: {
      state: documentTypeFilter,
      setState: setDocumentTypeFilter,
    },
    destination_country: {
      state: destinationCountryFilter,
      setState: setDestinationCountryFilter,
    },
    departure_date: {
      state: departureDateFilter,
      setState: setDepartureDateFilter,
    },
    trip_id: {
      state: tripIdFilter,
      setState: setTripIdFilter,
    },
    clearFilters,
  };
};
