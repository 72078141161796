import React, { Component } from 'react';
import { makeRequest } from '../../features/communication/request';
import { Container, Row, Col } from 'reactstrap';
import { Form, Button, message, Progress, Spin, Tooltip } from 'antd';
import { WechatOutlined } from '@ant-design/icons';
import { CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import FormBuilder from 'antd-form-builder';
import 'antd/dist/antd.css';
import './MyTripsForm.css';
import ChatBotComponent from '../chatbot/chatbotWindow/chatbotWindow';

const SAVE_ACTION = 'Save';
const SUBMIT_ACTION = 'Submit';
const SAVE_REGION_ACTION = 'SaveRegions';
const MAPPING_TO_MESSAGE = {
  [SAVE_ACTION]: 'Form has been saved',
  [SUBMIT_ACTION]: 'Form has been submitted',
  [SAVE_REGION_ACTION]: 'Region has been changed',
};

class MyForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      headformData: null,
      formData: null,
      formsMetaData: null,
      finish: false,
      formInformation: null,
      submitted: false,
      loading: true,
      chatVisible: false,
      openChatbot: false,
      endpoint: process.env.REACT_APP_BACKEND_API+ '/form2' + this.props.match.params.id,
      headform: process.env.REACT_APP_BACKEND_API+'/headform' + this.props.match.params.id,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getFormContent();

    if (!this.state.submitted && !this.state.formInformation) {
      setTimeout(() => {
        this.updateProgress();
      }, 1400);
    }

    setTimeout(() => {
      this.setState({ chatVisible: true });
    }, 4000);
  }

  toggleChatbot = () => {
    this.setState({ openChatbot: !this.state.openChatbot });
  };

  getFormContent = () => {
    makeRequest({
      method: 'get',
      url: 'apimigrationv1/' + this.props.match.params.id,
    })
      .then((resp) => {
        let forms_ = [];
        if (typeof resp.data.form === 'string') {
          this.setState({
            formInformation: resp.data.form,
            loading: false,
          });
        } else {
          resp.data.form.forEach((category) => {
            Object.entries(category).forEach(([key, value]) => {
              forms_.push({ category: key, value: this.getMeta(value) });
            });
          });
          this.setState({
            formsMetaData: forms_,
            loading: false,
          });
          this.updateProgress();
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  changeRegion = (evt) => {
    this.setState({ formsMetaData: null, loading: true });
    this.sendForm(evt, SAVE_REGION_ACTION);
  };

  handleFinish = (evt) => {
    this.sendForm(evt, SUBMIT_ACTION);
  };

  saveForm = (evt) => {
    this.sendForm(evt, SAVE_ACTION);
  };

  sendForm = (evt, action) => {
    makeRequest({
      method: 'post',
      url: 'apimigrationv1/' + this.props.match.params.id + '?action=' + action,
      body: this.formRef.current.getFieldsValue(),
    })
      .then((resp) => {
        if (action === 'SaveRegions') {
          this.formRef.current.resetFields();
          this.setState({ formsMetaData: null });
          this.getFormContent();
        } else {
          message.info(MAPPING_TO_MESSAGE[action]);
        }
        if (action === SUBMIT_ACTION) {
          this.setState({ submitted: true });
        }
      })
      .catch((error) => {
        error.response ? message.error(error.response.data) : message.error(action + ' error');
      });
  };

  updateProgress = () => {
    if (!this.formRef.current) {
      return;
    }
    let allfields = Object.values(this.formRef.current.getFieldsValue());
    let filledfields = allfields.filter((el) => el);

    this.setState({ formProgress: Math.round((filledfields.length / allfields.length) * 100) });
  };

  getMeta = (data) => {
    const enrichedData = data.map((field) => {
      if (field.key === 'Regions') {
        return {
          ...field,
          rules: [{ required: true, message: `${field.label} is required` }],
          widgetProps: { onChange: this.changeRegion },
        };
      } else {
        return {
          ...field,
          rules: [{ required: true, message: `${field.label} is required` }],
        };
      }
    });

    let meta = {
      columns: 1,
      formItemLayout: null,
      colon: true,
      fields: enrichedData,
    };

    return meta;
  };

  render() {
    return (
      <Container fluid="true" className="screen-container">
        <div>
          <div className="header-container">
            <div className="layer">
              <Row className="mw" style={{ marginBottom: '10px' }}>
                <Col md={{ size: 6, offset: 3 }}>
                  <div className="header-text header-text_title">Business Travel Compliance</div>
                </Col>
              </Row>
              <Row className="mw">
                <Col md={{ size: 6, offset: 3 }}>
                  <div className="header-text header-text_description">
                    Additional Information Questionnaire
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <Row className="mw plr-20">
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              {this.state.headformData && !this.state.submitted && !this.state.formInformation ? (
                <div className="header-info">
                  {`Additional information is needed to file notifications with authorities\
                    for your upcoming trip from ${this.state.headformData.travel_from} to ${
                    this.state.headformData.travel_to
                  }\
                    on ${moment(new Date(Date.parse(this.state.headformData.travel_date))).format(
                      'MMMM Do YYYY'
                    )}.`}
                </div>
              ) : null}
            </Col>
          </Row>
          <>
            {!(this.state.submitted || this.state.formInformation) ? (
              <div className="form-container">
                <Row className="plr-20">
                  <Col sm="12" md={{ size: 12 }}>
                    <Progress strokeColor="#DB536A" percent={this.state.formProgress} />
                  </Col>
                </Row>

                <Row className="plr-20">
                  <Col sm="12" md={{ size: 12 }}>
                    <div>
                      <Form
                        ref={this.formRef}
                        layout="vertical"
                        onFinish={this.handleFinish}
                        onValuesChange={this.updateProgress}
                        validateMessages={{ required: '' }}
                      >
                        {this.state.formsMetaData
                          ? this.state.formsMetaData.map((element) => {
                              return (
                                <fieldset>
                                  <div className="category">{element.category}</div>
                                  <FormBuilder meta={{ ...element.value }} form={this.formRef} />
                                </fieldset>
                              );
                            })
                          : null}
                        {this.state.loading ? <Spin /> : null}
                        <Form.Item className="form-footer">
                          <div className="buttons-container">
                            <Button
                              size="large"
                              onClick={this.saveForm}
                              className="action-button save-button"
                              style={{ color: '#DB536A', borderColor: '#DB536A' }}
                            >
                              Save Draft
                            </Button>
                            <Button
                              type="primary"
                              size="large"
                              className="action-button"
                              htmlType="submit"
                            >
                              Submit
                            </Button>
                            &nbsp; &nbsp;
                          </div>
                        </Form.Item>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="submitted-form">
                {this.state.submitted && (
                  <span>
                    <CheckOutlined />
                  </span>
                )}
                <span>
                  {this.state.formInformation ||
                    'Thank you for responding to the myTrips+ Questionnaire'}
                  .
                </span>
              </div>
            )}
          </>
          {this.state.chatVisible ? (
            <div className="chat">
              {this.state.openChatbot ? (
                <div className="chatbot">
                  <ChatBotComponent />
                </div>
              ) : null}
              <Tooltip title={this.state.openChatbot ? 'Close chat' : 'Open Chatbot'}>
                <WechatOutlined
                  onClick={this.toggleChatbot}
                  style={{ fontSize: '32px', color: '#db536a' }}
                />
              </Tooltip>
            </div>
          ) : null}
        </div>
      </Container>
    );
  }
}

export default MyForms;
