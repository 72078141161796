import React from 'react';
import { Route } from 'react-router-dom';
import { Checkbox, Modal, Typography, Space, Spin } from 'antd';

import { AuthService } from '../../services/authService';

const { Link } = Typography;

const TERMS_AND_CONDITIONS = 'termsAndConditions';
const YEAR_MILISECONDS = 3.15576e10;

const termsAndConditionsText00 = `These terms of use apply to your use of “My Trips Plus,” the Vialto Partners (“Vialto Partners” refers to the entity identified at `;
const termsAndConditionsText01 = `, its affiliates and other entities in the Vialto professional network)  My Trips Plus downstream business travel compliance application. These terms of use may be updated from time to time by Vialto Partners in its sole discretion, and such updates will be effective once posted, unless otherwise indicated.`;

const termsAndConditionsText1 = `Each member firm in the Vialto Partners network (Vialto firm) is a separate legal entity. For further details, please see `;

const termsAndConditionsText2 = `If you’re a client or represent a client, your use of My Trips Plus is governed by the engagement contract or other applicable agreement between the client and Vialto Partners. In the event of a conflict between these terms of use
and said engagement contract or other applicable agreement, the engagement contract or other applicable agreement governs. If you use My Trips Plus in connection with your role at a Vialto firm, your use of My Trips Plus is governed by your agreement 
with such firm.`;

const termsAndConditionsText3 = `1. Security`;

const termsAndConditionsText41 = `You must keep your log on credentials secure and confidential. If you know or suspect that someone else may have access to your credentials, tell your Vialto Partners contact immediately. Contact information is available at `;
const termsAndConditionsText42 = `. You must not attempt or take any actions that could compromise the security of My Trips Plus.`;

const termsAndConditionsText5 = `2. Your conduct`;

const termsAndConditionsText6 = `You must not use My Trips Plus for any fraudulent or unlawful purpose. When using My Trips Plus, you must comply with all applicable laws and regulations, and you must not upload, post or link to anything that is illegal or infringes anyone else's intellectual property, confidentiality or other rights, or is harmful, including any virus or other computer code designed or likely to harm My Trips Plus or any device, or is defamatory, libellous, threatening, abusive, offensive, misleading or otherwise inappropriate. If you access content on My Trips Plus that is not intended for you, please exit My Trips Plus immediately and let your Vialto Partners contact know. Contact information is available at `;

const termsAndConditionsText7 = `3. No representations, warranties or liability to you`;

const termsAndConditionsText8 = `No Vialto firm makes any representations or provides any warranties to you about My Trips Plus or content on My Trips Plus. You are responsible for all use of (including access to) My Trips Plus by you and any other person or entity to whom you make My Trips Plus available. To the fullest extent permitted under applicable law: (a) My Trips Plus is made available to you on an “as is,” “where is” and “where available” basis, without any warranties of any kind, whether express, implied, or statutory; and (b) Vialto Partners disclaims all warranties with respect to My Trips Plus, including the warranties of merchantability, fitness for a particular purpose, non- infringement and title. To the fullest extent permitted under applicable law, Vialto Partners will not be liable for any indirect, incidental, consequential, special, exemplary or punitive damages of any kind, under any contract, tort (including negligence), strict liability or other theory, including damages for lost profits, use or data, loss of other intangibles, loss of security of submissions (including unauthorized interception by third parties of any submissions), even if advised in advance of the possibility of damages or losses. Without limiting the foregoing, Vialto Partners will not be liable for damages of any kind resulting from your use of or inability to use My Trips Plus. Your sole and exclusive remedy for dissatisfaction with My Trips Plus  is to stop using My Trips Plus.`;

const termsAndConditionsText9 = `Vialto Partners reserves all rights.`;



export const LoginRoute = ({ onLoggedIn, onNotLogged, ...rest }) => {
  const [isAcceptChecked, setIsAcceptChecked] = React.useState(false);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = React.useState(() => {
    const rawTacDate = JSON.parse(localStorage.getItem(TERMS_AND_CONDITIONS));
    if (!rawTacDate) {
      return null;
    }
    const tacDate = JSON.parse(rawTacDate);
    if (!tacDate || (new Date()).getTime() - tacDate > YEAR_MILISECONDS) {
      return null;
    }
    return rawTacDate;
  });

  React.useEffect(() => {
    localStorage.setItem(TERMS_AND_CONDITIONS, JSON.stringify(termsAndConditionsAccepted));
  }, [termsAndConditionsAccepted]);

  if (!AuthService.isAuthenticated()) {
    onNotLogged();
    return <Spin />;
  }

  if (!termsAndConditionsAccepted) {
    return (
      <Modal
        title="Terms and Conditions"
        visible
        okText="Accept"
        okButtonProps={{ disabled: !isAcceptChecked }}
        onOk={() => setTermsAndConditionsAccepted((new Date()).getTime())}
        cancelButtonProps={{ style: { display: 'none' } }}        
        //cancelText="Decline"
        //onCancel={() => onNotLogged()}
        bodyStyle={{ maxHeight: '70vh', overflowY: 'auto' }}
        width="70vw"
      >
        <Space direction="vertical" size={20}>
          <Typography>{termsAndConditionsText00}<Link href='https://vialto.com/about' target="_blank">vialto.com/about</Link>{termsAndConditionsText01}</Typography>
          <Typography>{termsAndConditionsText1}<Link href='https://vialto.com/about' target="_blank">vialto.com/about</Link>.</Typography>
          <Typography>{termsAndConditionsText2}</Typography>
          <Typography variant="h1" component="h6">{termsAndConditionsText3}</Typography>
          <Typography>{termsAndConditionsText41}<Link href='https://vialto.com/about' target="_blank">vialto.com/about</Link>{termsAndConditionsText42}</Typography>
          <Typography variant="h1" component="h6">{termsAndConditionsText5}</Typography>
          <Typography>{termsAndConditionsText6}<Link href='https://vialto.com/about' target="_blank">vialto.com/about</Link>.</Typography>
          <Typography variant="h1" component="h6">{termsAndConditionsText7}</Typography>
          <Typography>{termsAndConditionsText8}</Typography>
          <Typography>{termsAndConditionsText9}</Typography>
          
          
          
          <Checkbox checked={isAcceptChecked} onChange={() => setIsAcceptChecked(!isAcceptChecked)}>
            I agree to Terms and Conditions of My Trips Plus
          </Checkbox>
        </Space>
      </Modal>
    )
  };

  return (
    <Route {...rest} render={(props) => onLoggedIn()} />
  );
}
