import React from 'react';
import { Modal } from 'antd'
import PrivacyContent from './privacyContent';
import CookiesContent from './cookiesContent';

const PRIVACY_STATEMENT = "Privacy Statement";
const COOKIES_POLICY = "Cookies Policy";

const InfoModal = props => (
    <Modal title={props.type === 'privacy' ? PRIVACY_STATEMENT : COOKIES_POLICY} {...props} width={1000}>
        {props.type === 'privacy' && <PrivacyContent />}
        {props.type === 'cookies' && <CookiesContent />}    
    </Modal>
)

export default InfoModal; 