import React, { Component } from 'react';
import BaseLayout from '../layouts/baseLayout/baseLayout';
import Orchestrator from '../components/orchestrator/orchestrator';

class OrchestratorPage extends Component {
    render(){
        return (
            <BaseLayout>
                <Orchestrator {...this.props}/>           
            </BaseLayout>
        );
    }
}

export default OrchestratorPage;
