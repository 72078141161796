import React, { useMemo } from 'react';
import moment from 'moment';
import { Link, useLocation,useHistory } from 'react-router-dom';

export default function AdminConfig() {
    const history = useHistory();
    const navigate = (tripId) =>{
        sessionStorage.setItem('isOpen',true)
                history.push({
            pathname: '/AdminAction',
 state: tripId,
 isOpen: sessionStorage.getItem('isOpen')
        });
    }
  var date = new Date();
  let assignZeroMonth = '';
  let assignZeroDate = '';
  if (date.getMonth() + 1 < 10) {
    assignZeroMonth += 0;
  }
  if (date.getDate() < 10) {
    assignZeroDate += 0;
  }
  var current_date =
    date.getFullYear() +
    '-' +
    assignZeroMonth +
    (date.getMonth() + 1) +
    '-' +
    assignZeroDate +
    date.getDate();

  let assignZero = '';
  let assignMonthZero = ''
  var fromdate = new Date();

  fromdate.setDate(fromdate.getDate() - 3);

  var yesterday = fromdate.getDate();
  var previousmonth = fromdate.getMonth() + 1
  if(previousmonth < 10){
    assignMonthZero += 0;
  }

  if (yesterday < 10) {
    assignZero += 0;
  }
  var from_date =
    fromdate.getFullYear() +
    '-' +
    assignMonthZero +
    (fromdate.getMonth() + 1)  +
    '-' +
    assignZero +
    yesterday;

  const columns = useMemo(
    () => [
      {
        Header: 'TripId',
        accessor: 'tripid',
      },
      {
        Header:'Record Locator',
        accessor:'pnr'
      },
      {
        Header: 'Trip Created',
        accessor: d => {
          return  moment(d.tripcreatedon)
            .local()
            .format("YYYY-MM-DD")
        },
      },    
      {
        Header: 'Trip Canceled',
        accessor: d => {
          return d.tripcancelledon !== null ? moment(d.tripcancelledon)
            .local()
            .format("YYYY-MM-DD") : ''
        },
      },        
      {
        Header: 'First Name',
        accessor: 'firstname'
      },
      {
        Header:'Last Name',
        accessor:'lastname'
      },
      {
        Header:'Primary Nationality',
        accessor:'primarynationalitycountry'
      },
      {
        Header: 'Secondary Nationality',
        accessor: 'secondarynationalitycountry'
      },
      {
        Header: 'Email Address',
        accessor: 'emailaddress',
      },
      {
        Header: 'Home Office',
        accessor: 'homeofficelocation',
      },
      {
        Header:'Cost Center',
        accessor: 'costcenter'
      },
      {
        Header: 'From',
        accessor:'fromlocationcode'
      },
      {
        Header: 'To',
        accessor:'tolocationcode'
      },      
      {
        Header:'Departure Date',
        accessor:d => {
          return moment(d.fromlocaldate)
            .local()
            .format("YYYY-MM-DD")
        },
      },
      {
        Header:'Return Date',      
        accessor:d => {
          return moment(d.returndate)
            .local()
            .format("YYYY-MM-DD")
        },
      },
      {
        Header: 'PWD',
        accessor: 'pwd',
        Cell: props => {
          let isShowLink = true
          if(props.cell.row.original.pwdstatus === 'Complete' || props.cell.row.original.pwdstatus === 'New' || props.cell.row.original.pwdstatus === 'HRIS' || props.cell.row.original.pwdstatus === 'ReportLateTrip' || props.cell.row.original.pwdstatus === 'Stop on SalaryThreshold' || props.cell.row.original.pwdstatus === 'Reporting' || props.cell.row.original.pwdstatus === null){
            isShowLink = false
          }
          return props.value === 'Registration required' && isShowLink !== false ? <span to="/PWDManualSubmission" className='link_navigate'  onClick={() => navigate(props.cell.row.original)}> {props.value}</span> : <span>{props.value}</span>
        }
      },
      {
        Header: 'PWD Status',
        accessor: 'pwdstatus',
      },
      {
        Header: 'PWD RPA Processing',
        accessor: 'pwdrpaprocessing',
      },      
      {
        Header: 'GSS',
        accessor: 'gss',
      },      
      {
        Header: 'GSS Status',
        accessor: 'gssstatus',
      },
      {
        Header: 'GSS RPA Processing',
        accessor: 'gssrpaprocessing',
      },
      {
        Header: 'Status',
        accessor:'status'
      },
      {
        Header:'Booked',
        accessor:'booked'
      },
      {
        Header:'Trip Updated On',   
        accessor:d => {
          return moment(d.tripupdatedon)
            .local()
            .format("YYYY-MM-DD THH:mm")
          },
      },      
      {
        Header: 'Updated Date',    
        accessor:d => {
          return moment(d.updated)
            .local()
            .format("YYYY-MM-DD THH:mm")
        },
      },
      {
        Header: 'Event Source',
        accessor:'source'
      },
      {
        Header: 'BusinessUnitTitle',
        accessor: 'businessunittitle'
      },
      {
        Header: 'PersonalAssistant',
        accessor: 'personalassistant'
      },
      {
        Header: 'Custom Data',
        accessor: 'customdata'
      },
    ],
    []
  );
  
  const perPageItems = [
    {
      value: 10,
      title: 10,
    },
    {
      value: 25,
      title: 25,
    },
    {
      value: 50,
      title: 50,
    },
    {
      value: 100,
      title: 100,
    }
  ];

  const HideColumns = [
    'pnr',  
    'firstname',
    'lastname',
    'primarynationalitycountry',
    'secondarynationalitycountry',
    'homeofficelocation',
    'costcenter',
    'tolocaldate',
    'booked',
    'tripupdatedon',
    'updated',
    'source',
    'businessunittitle',
    'personalassistant',
    'pwdrpaprocessing',
    'gssrpaprocessing',
    'customdata',
];


return { columns, perPageItems, HideColumns, current_date, from_date};
}
