import { useEffect, useState, useRef } from 'react';
import DateFilter from '../DateFilter';
import InputFilter from '../InputFilter';
import SelectFilter from '../SelectFilter';
import API from 'Pages/TravelCompliancePage/api';
import {Modal} from 'antd';

import * as Styled from './Filters.styles';

const Filters = () => { 
  const fileref = useRef();
  const [tripId, setTripId] = useState('');
  const [mail, setMail] = useState('');
  const [pnr, setPNR] = useState('');
  const [msw_countries, setMSWCountries] = useState('');
  const  [error,setError] = useState([])
  const [file, setFile] = useState('');
  const [departure_date, setDeparture_date] = useState('');
  const [dictionaries, setDictionaries] = useState([]);
  const [destination_country, setDestination_country] = useState([]);
  const [obligation_and_document_type, setObligation_and_document_type] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState('');
  const [loading, setLoading] = useState(false);

  const uploadSuccess = () => {
    Modal.success({
      content: 'File has been uploaded successfully.',
    });
    setTripId('')
    setMail('')
    setPNR('')
    setFile('')
    setMSWCountries('');
    fileref.current.value=''
    setDestination_country('')
    setObligation_and_document_type('')
    setDeparture_date('')
    setCustomer('')
  };

  const uploadError = () => {
    Modal.error({
      title: 'Error',
      content: 'Something went wrong. Please try again later.',
    });
  };

  const uploadFile = (event) => {
    setFile(event.target.files[0]);
  }

  useEffect(() => {
    API.getDropdownItems().then((response) => setDictionaries(response));
    API.getClients().then((response) => setCustomers(response.customers));
  }, []);


  const removeError = (field) =>{
    
    let filteredArray = error.filter(item => item.error !== field)
    setError(filteredArray)
  }

const isValidFields = () => {
  if(!tripId || !mail || !departure_date || !file){
  
  if(!tripId){
    let isItemAvailable = error.some(item => item.error === "TripId")
    if(!isItemAvailable)
    setError((err) => [...err, {"error": "TripId" , "message": "TripId is required"}])
  }
  if(!mail){
    let isItemAvailable = error.some(item => item.error === "Traveller email")
    if(!isItemAvailable)
    setError((err) => [...err,{"error": "Traveller email" , "message": "Email is required"}])
  }
  if(destination_country.length === 0){
    let isItemAvailable = error.some(item => item.error === "Destination Country")
    if(!isItemAvailable)
    setError((err) => [...err,{"error": "Destination Country" , "message": "Destination Country is required"}])
  }
  if(obligation_and_document_type.length === 0){
    let isItemAvailable = error.some(item => item.error === "Document type")
    if(!isItemAvailable)
    setError((err) => [...err,{"error": "Document type" , "message": "Document type is required"}])
  }
  if(!departure_date){
    let isItemAvailable = error.some(item => item.error === "Departure Date")
    if(!isItemAvailable)
    setError((err) => [...err,{"error": "Departure Date" , "message": "Departure Date is required"}])
  }
  if(!customer){
    let isItemAvailable = error.some(item => item.error === "Customer")
    if(!isItemAvailable)
    setError((err) => [...err,{"error": "Customer" , "message": "Customer name is required"}])
  }
  if(!file){
    let isItemAvailable = error.some(item => item.error === "file")
    if(!isItemAvailable)
    setError((err) => [...err,{"error": "file" , "message": "PDF file is required"}])
  }
  return false
}

  return true

}

const isValidEmail = (mail)=>{
  
  if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mail)){
    setError((err) => [...err,{"error": "Traveller email" , "message": "Please enter a valid email"}])
    return false
  }
    return true
  
}

const isValidFileType = (file) =>{

  let isItemAvailable = error.some(item => item.error === "file")
  
  if(file.type === 'application/pdf'){
    return true
  }
  if(!isItemAvailable){
  setError((err) => [...err,{"error": "file" , "message": 'Only pdf files are allowed to be uploaded'}])
    return false
  }

}

  const handleUploadFile = () => {
   mail && isValidEmail(mail)
   file && isValidFileType(file)
    if(isValidFields() && error.length === 0){
    setLoading(true);
    try {
      const customerId = customers.find(({ name }) => customer === name).id

      API.uploadFile(customerId, {
        file,
        tripId,
        mail,
        pnr,
        destination_country,
        departure_date,
        obligation_and_document_type,
        msw_countries
      }).then((res) => {
        uploadSuccess();
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        
      })
    } catch (error) {
      uploadError();
      setLoading(false);
    }
  }
  }

  return <Styled.ButtonWrapper>
    <InputFilter type={'text'} setState={setTripId} removeError={(val)=>removeError(val)} error={error} state={tripId} label="TripId"  />
    <InputFilter type={'email'} setState={setMail} validateEmail={(val) => isValidEmail(val)} removeError={(val)=>removeError(val)} error={error} state={mail} label="Traveller email" />
    <InputFilter type={'text'} setState={setPNR} removeError={(val)=>removeError(val)} error={error} state={pnr} label="Record Locator" />
    <SelectFilter 
      state={destination_country}
      setState={setDestination_country}
      options={dictionaries.country}
      removeError={(val)=>removeError(val)} 
      error={error}
      label="Destination Country"
      type="text"
    />
    <SelectFilter 
      state={obligation_and_document_type}
      setState={setObligation_and_document_type}
      removeError={(val)=>removeError(val)} 
      error={error}
      options={dictionaries.obligation_and_document_type}
      label="Document type"
      type="text"
    />
    <DateFilter 
      setState={setDeparture_date}
      state={departure_date}
      removeError={(val)=>removeError(val)}
       error={error}
      format="DD/MM/YYYY"
      label="Departure Date"
    />
    { customers && customers.length > 0 ? <SelectFilter
      state={customer}
      setState={setCustomer}
      removeError={(val)=>removeError(val)} 
      error={error}
      options={customers.map(({ name }) => name)}
      label="Customer"
      type="text"

    /> : null }
    <InputFilter type={'text'} setState={setMSWCountries} removeError={(val)=>removeError(val)} error={error} state={msw_countries} label="MSW Countries" />
    <t/>
    <div className='upload_file_MT'>
    <input onChange={uploadFile} ref={fileref} className='required' type="file" label="file" onClick={()=>removeError('file')} 
     />
     
       {error && error.map((field) => {
        if(field.error === 'file'){
          return <div className='error_msg_uploadgfile'>{field.message}</div>
        }
      })}
      </div>
    <Styled.Button
      type="primary"
      onClick={handleUploadFile}
      loading={loading}
    >
      Upload
    </Styled.Button>
  </Styled.ButtonWrapper>
}; 

export default Filters;