import { makeRequest } from 'features/communication/request';
import moment from 'moment';
import {itIsEmail, itIsPNR} from "../components/Table/table.definition";
import history from "../../../features/routing/history";

const downloadFile = (uuid, file) => {
  makeRequest({
    method: 'download',
    url: 'v1/downloadfile/' + uuid,
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = url; 
    link.setAttribute('download', file);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

const getDropdownItems = () => {
  const promise = makeRequest({
    method: 'get',
    url: 'v1/dropdowns',
  });

  return promise.then((response) => response.data);
};

const getUserFile = (uuid) => {
  const promise = makeRequest({
    method: 'get',
    url: 'v1/userfile/' + uuid,
  });

  return promise.then((response) => response.data);
};

const getUserFiles = (userEmail, paginationState, filtersState, sorterState) => {
  const sorterParams = sorterState.sort_direction ? sorterState : null;
  const query = new URLSearchParams(history.location.search);

  let params = {
    page_num: paginationState,
    page_size: 5,
    ...sorterParams,
  };

  if (query.has("email")) {
    params = {
      email: query.get("email"),
      ...params
    }
  }

  const body = {
    filter: {
      pnr: userEmail !== undefined && !itIsEmail(userEmail) ? userEmail : filtersState.pnr.state,
      obligation_and_document_type: filtersState.obligation_and_document_type.state,
      destination_country: filtersState.destination_country.state,
      departure_date: filtersState.departure_date.state && moment(filtersState.departure_date.state).format('YYYY-MM-DD'),
      tripId: filtersState.trip_id.state
    },
  };

  const url = itIsEmail(userEmail) ? 'v1/userfiles/' + userEmail : itIsPNR(userEmail) ? `v1/userfiles?strict=True&id=${userEmail}` : `v1/userfiles`;

  const promise = makeRequest({
    method: 'post',
    url: url,
    body,
    params,
  });

  return promise.then((response) => response.data);
};

const getUser = () => {
  const promise = makeRequest({
    method: 'get',
    url: 'v1/users/me/',
  });

  return promise.then((response) => response.data);
};

const uploadFile = (
  customer,
  { file, tripId, mail, pnr, destination_country, departure_date, obligation_and_document_type, msw_countries }
) => {
  const params = {
    tripId,
    mail,
    pnr,
    destination_country,
    departure_date: moment(new Date(Date.parse(departure_date))).format('DD/MM/YYYY'),
    obligation_and_document_type,
    msw_countries
  };

  const formData = new FormData();
  formData.append('file', file);
  const promise = makeRequest({
    method: 'post',
    url: 'v1/uploadfile/',
    params,
    body: formData,
    headers: {
      customer,
    },
  });

  return promise.then((response) => response.data);
};

const getClients = () => {
  const promise = makeRequest({
    method: 'options',
    url: 'v1/uploadfile/',
  });

  return promise.then((response) => response.data);
};
const getV3Clients = () => {
  const customerList = {
    customers: [
      { name: 'TPE', id: 'tpe' },
      { name: 'TST', id: 'tst' }
    ]
  }
  return customerList;
};

const API = {
  getUser,
  getUserFile,
  getUserFiles,
  getDropdownItems,
  downloadFile,
  uploadFile,
  getClients,
  getV3Clients
};

export default API;
