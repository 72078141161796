import React, { Component } from 'react';
import { makeRequest } from '../../features/communication/request';
import { Container } from 'reactstrap';
import { message } from 'antd';
import 'antd/dist/antd.css';
import './orchestrator.css';
import AuthIFrame from 'react-auth-iframe';

class MyForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      kibanaDashboards: null,
      kibanaReporter: null,
      src: '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    makeRequest({
      method: 'get',
      url: 'checkReporter',
    })
      .then((resp) => {
        if (resp.data.roles.includes('Admin') || resp.data.roles.includes('Orchestrator')) {
          let orch_src =
            process.env.REACT_APP_ORCHESTRATOR_DASHBOARD + '?' + 'auth=' + resp.data.token;
          window.location = orch_src;
        } else {
          message.info("You don't have permission to show orchestrator");
          this.setState({ orchestrator: false });
        }
      })
      .catch((err) => err);
  }

  render() {
    return (
      <Container fluid="true" className="screen-container">
        <div className="footer">
          <div className="vialto"></div>
          <div className="footer-copyrights">©2023 Vialto All rights reserved</div>
        </div>
      </Container>
    );
  }
}

export default MyForms;
