import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import uuid from 'react-uuid';
import './antd-theme.css';
import { Provider } from 'react-redux';
import store from './redux/store';


localStorage.setItem('sender', uuid());

const app = (
  
  <Provider store={store}>
    <App />
  </Provider>
  
);

//ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
