import React, { Component } from 'react';
import BaseLayout from '../layouts/baseLayout/baseLayout';
import Events from '../components/events/events';

class EventsPage extends Component { 
    render(){
        return (
            <BaseLayout>
                <Events {...this.props}/>           
            </BaseLayout>
        );
    }
}

export default EventsPage;
