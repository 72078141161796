import * as Styles from './Table.styles.jsx';

export const itIsUID = (data) => {
  return data !== undefined && data.match(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/);
};

export const itIsEmail = (data) => {
  return data !== undefined && data.match(/^\S+@\S+\.\S+$/);
};

export const itIsPNR = (data) => {
  return data !== undefined && !itIsEmail(data)
}

export const mapDataToTable = (data) => {
  return data.rows.map((item) => {
    return {
      ...item,
      key: item.uuid,
    };
  });
};

export const praseDate = (value) => {
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
  };

  const date = new Date(value);

  const day = padTo2Digits(date.getDate());
  const month = padTo2Digits(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const tableDefinition = [
  {
    title: 'Record Locator',
    sorter: true,
    dataIndex: 'pnr',
    showSorterTooltip: false,
    width: '10%',
  },
  {
    title: 'TripId',
    sorter: true,
    dataIndex: 'tripId',
    showSorterTooltip: false,
    width: '10%',
  },
  {
    title: 'Document type',
    sorter: true,
    dataIndex: 'obligation_and_document_type',
    showSorterTooltip: false,
    width: '20%',
  },
  {
    title: 'Destination Country',
    sorter: true,
    dataIndex: 'destination_country',
    showSorterTooltip: false,
    width: '20%',
  },
  {
    title: 'Departure Date',
    sorter: true,
    dataIndex: 'departure_date',
    showSorterTooltip: false,
    width: '20%',
    render: (value) => praseDate(value),
  },
  {
    title: 'MSW Countries',
    sorter: true,
    dataIndex: 'msw_countries',
    showSorterTooltip: false,
    width: '10%',
  },
  {
    title: 'File',
    dataIndex: '',
    showSorterTooltip: false,
    width: '10%',
    align: 'center',
    render: (_, record) => <Styles.Download record={record} />,
  },
];
