import React, { Component } from 'react';
import { makeRequest } from '../../features/communication/request';
import { Container } from 'reactstrap';
import { message } from 'antd';
import 'antd/dist/antd.css';
import './nifi.css';
import { Redirect } from 'react-router-dom';

class MyNifi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dest: {
        nifi: 'nifi/login',
        kibana: 'kibana/login',
        kibana7: 'kibana/app/home'
      },
      nifi: null,
      src: '',
    };
  }

  checkRole(data) {

    if (data.roles) {
      if (data.roles.includes('Admin')) {
        return true;
      }
    }
    if (data.context) {
      if (data.context === 'Admin') {
        return true;
      }
    }
    return false;
  }

  componentDidMount() {
    makeRequest({
      method: 'get',
      url: 'checkReporter',
    })
      .then((resp) => {
        if (this.checkRole(resp.data)) {
          let nifi_src = process.env.REACT_APP_BACKEND_API + this.state.dest[this.props.match.params.comp] + '?' + 'auth=' + resp.data.token;
          window.location = nifi_src;
          //this.setState({
          //  nifi: true,
          //  src: process.env.REACT_APP_NIFI_URL
          //})
        } else {
          message.info("You don't have permission to show " + this.props.match.params.comp);
          this.setState({ nifi: false });
        }
      })
      .catch((err) => err);
  }


  render() {
    return (
      <Container fluid="true" className="screen-container">
        {this.state.nifi ? <Redirect push to={this.state.src} /> : null}
        <div className="footer">
          <div className="vialto"></div>
          <div className="footer-copyrights">©2023 Vialto All rights reserved</div>
        </div>
      </Container>
    );
  }
}

export default MyNifi;
