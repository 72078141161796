import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { Tooltip } from 'antd';
import RasaBot from '../rasaBot/rasaBot';
import FirstMessage from '../firstMessage/firstMessage';
import { ReactComponent as ChatbotIcon } from '../../../assets/img/chatbot.svg';
import './chatbotWindow.css';
import { makeRequest } from '../../../features/communication/request';


const theme = {
  background: '#f5f8fb',
  headerBgColor: '#DB536A',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#DB536A',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

const config = {
  width: '350px',
  height: '550px',
};

class ChatBotComponent extends Component {
  constructor() {
    super();
    this.state = {
      isOpened: false,
      logSenderId: false,
    };
  }

  logStartConversation = () => {
    makeRequest({
      method: 'post',
      url: 'chatstart',
      body: {
        draft_id: this.props.tripId,
        sender_id: localStorage.getItem('sender'),
      },
    });
  };

  chatbotIsOpened = () => {
  
    if (!this.state.logSenderId) {
      this.logStartConversation();
      this.setState({
        isOpened: true,
        logSenderId: true,
      });
    } else {
      this.setState({ isOpened: !this.state.isOpened });
    }
  };

  render() {
    return (
      <div data-testid="bot">
        <ThemeProvider theme={theme}>
          <ChatBot
            bubbleStyle={{ maxWidth: '80%' }}
            className="chatbotWindow"
            headerTitle="Global Mobility Services (GMS) assistant."
            floating
            floatingStyle={{ backgroundColor: 'transparent', bottom: '150px', right: '43px' }}
            floatingIcon={
              <Tooltip title="Open Chatbot">
                <ChatbotIcon style={{ fontSize: '32px', fill: '#db536a' }} />
              </Tooltip>
            }
            toggleFloating={this.chatbotIsOpened}
            opened={this.state.isOpened}
            steps={[
              {
                id: '1',
                component: (
                  <FirstMessage
                    firstName={this.props.headData.FirstName}
                    tripId={this.props.tripId}
                  />
                ),
                asMessage: true,
                waitAction: true,
                trigger: 'message2bot',
              },
              {
                id: 'message2bot',
                user: true,
                trigger: '4',
              },
              {
                id: '4',
                component: <RasaBot />,
                asMessage: true,
                waitAction: true,
                trigger: 'message2bot',
              },
              {
                id: 'timeout',
                message: 'Is there anything else I can help you with?',
                trigger: 'message2bot',
              },
            ]}
            {...config}
          />
        </ThemeProvider>
      </div>
    );
  }
}
export default ChatBotComponent;
