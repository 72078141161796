import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as Styles from './Filters.styles';
import API from 'Pages/TravelCompliancePage/api';

const Filters = ({ filtersState, isFiltersDisable = false, applyFilters }) => {
  const [dictionaries, setDictionaries] = useState([]);
  const [isClear, setIsClear] = useState(true);
  const location = useLocation();
  const history = useHistory();

  const clearFilters = () => {
    filtersState.clearFilters();
    setIsClear(false);
  };

  useEffect(() => {
    if (!isClear) {
      setIsClear(true);
      applyFilters();
    }
  }, [applyFilters, isClear]);

  useEffect(() => {
    API.getDropdownItems().then((response) => setDictionaries(response));
  }, []);

  return (
    <Styles.FiltersBackground>
      <Styles.InputFilter
        {...filtersState.pnr}
        label="Record Locator"
        type="text"
        disabled={isFiltersDisable}
      />

      <Styles.SelectFilter
        {...filtersState.obligation_and_document_type}
        options={dictionaries.obligation_and_document_type}
        label="Document type"
        type="text"
        disabled={isFiltersDisable}
      />

      <Styles.SelectFilter
        {...filtersState.destination_country}
        options={dictionaries.country}
        label="Destination Country"
        type="text"
        disabled={isFiltersDisable}
      />

      <Styles.InputFilter
        {...filtersState.trip_id}
        label="TripId"
        type="text"
        disabled={isFiltersDisable}
      />

      <Styles.DateFilter
        {...filtersState.departure_date}
        label="Departure Date"
        format="DD/MM/YYYY"
        disabled={isFiltersDisable}
      />

      <div>
        <Styles.FilterButton
          type="primary"
          onClick={() => applyFilters()}
          disabled={isFiltersDisable}
        >
          Apply
        </Styles.FilterButton>
        <Styles.FilterButton
          type="default"
          onClick={() => clearFilters()}
          disabled={isFiltersDisable}
        >
          Clear Filters
        </Styles.FilterButton>
      </div>
    </Styles.FiltersBackground>
  );
};

export default Filters;
