import React from 'react';
import { Table } from 'antd';

const COLUMNS = [
    {
        title: 'Cookie name',
        dataIndex: 'cookieName',
        key: 'cookieName',
        render: (text) => (
            <>
                {text.split(';').map((cookie) => <>{cookie}<br/></>)}
            </>
          ),
    },
    {
        title: 'First or third party Cookie?',
        dataIndex: 'cookieParty',
        key: 'cookieParty',
    },
    {
        title: 'Cookie Duration',
        dataIndex: 'cookieDuration',
        key: 'cookieDuration',
    },
    {
        title: 'Cookie Description',
        dataIndex: 'cookieDescription',
        key: 'cookieDescription',
    },
    {
        title: 'Information shared with',
        dataIndex: 'informationSharedWith',
        key: 'informationSharedWith',
    }
];

const DATA = [
    {
        key: 1,
        cookieName: `AMCV_;_abck;_ga;amlbcookie;amstg_encrypt;dtCookie;dtLatC;dtPC;dtSa;
                     ib.ofisHRD;ib.ofisREMEMBER_ME;incap_ses_;loginstg_encrypt;mbox;nlbi_;rxVisitor;
                     rxvt;s.ofisHRD;s.ofisREMEMBER_ME;s_nr;visid_incap_`,
        cookieParty: 'First party',
        cookieDuration: 'Limited to: Single session, maximum 5 hours lifetime',
        cookieDescription: 'The named cookies are used to facilitate the authorization process',
        informationSharedWith: 'Vialto Partners'
    },
];

const CookiesContent = () => (
    <>
        <h5>Introduction</h5>
        <p>
        My Trips Plus (“application”)  automates downstream processing of EU Posted Workers Directive registrations and global (worldwide) Social Security filings. We've designed this information page to provide our application visitors with accessible, transparent information about the cookies we use. <br/><br/>
        This information is relevant for visitors to My Trips Plus. For more information about how we process your personal data collected through My Trips Plus, please visit our privacy statement.
        </p>
        <h5>Changes to this Cookie Policy</h5>
        <p>
        We recognise that transparency is an ongoing responsibility so we will keep this Cookie Policy under regular review.<br/><br/>
        This cookie policy was last updated on the date identified at <a href="https://vialto.com">vialto.com</a>
        </p>
        <h5>What are cookies?</h5>
        <p>
        Cookies are small text files that are placed on your computer by the applications that you visit. They are used in order to make applications work, or work more efficiently, as well as to provide statistical information to site owners. Some cookies also enable the display of relevant advertising when you move from site to site.  <br/><br/>
        <strong>We use the following types of cookies on our site:</strong><br/><br/>
        We use the following types of cookies on our site: <br/><br/>
        <strong>Necessary Cookies</strong><br/><br/>
        These cookies are necessary for our application to operate. Our application cannot function without these cookies so they are always set on. <br/><br/>
        You can manage and control cookies through your browser (a good search term is ‘managing cookies’). Your choices will include removing cookies by deleting them from your ‘browser history’ when you leave our site. If you do switch cookies off for our site it may not work as well as you would expect it to. <br/>
        </p>
        <h5>Section A: Strictly necessary cookies</h5>
        <p>
        <Table columns={COLUMNS} dataSource={DATA} pagination={{hideOnSinglePage: true}}/>
        </p>
        <p>Vialto Partners (“Vialto Partners” refers to the entity identified at <a href="https://vialto.com/about">vialto.com/about</a>, its affiliates and other entities in the Vialto professional network) </p>
        <h5>Managing cookies on your device</h5>
        <p>
        We use cookies to personalize content and to provide you with an improved user experience. You can control and manage cookies using your browser (see below). Please note that removing or blocking cookies can impact your user experience and some functionality may no longer be available.
        </p>
        <h5>Using your browser to control cookies</h5>
        <p>
        Most browsers allow you to view, manage, delete and block cookies for a website. Be aware that if you delete all cookies then any preferences you have set will be lost, including the ability to opt-out from cookies as this function itself requires placement of an opt out cookie on your device. Guidance on how to control cookies for common browsers is linked below. <br/><br/>
        <a target="_blank" rel="noreferrer" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">Google Chrome</a><br/>
        <a target="_blank" rel="noreferrer" href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">Mozilla Firefox</a><br/>
        <a target="_blank" rel="noreferrer" href="https://support.apple.com/en-us/HT201265">MacOS Safari</a><br/>
        <a target="_blank" rel="noreferrer" href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Microsoft Internet Explorer</a><br/>
        <br/><br/>
        For information on additional browsers and device types please see <a rel="noreferrer" target="_blank" href="http://www.aboutcookies.org/">http://www.aboutcookies.org/</a>  or <a href="http://www.cookiecentral.com/faq/">http://www.cookiecentral.com/faq/</a>. 
        </p>
    
        
    </>
);

export default CookiesContent;
