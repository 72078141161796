
import Title from './components/Title';
import BaseLayout from 'layouts/baseLayout';
import Filters from './components/Filters';

const UploadDocumentPage = () => {
  return (
    <BaseLayout>
      <Title title="Upload Document" />
      <Filters />
    </BaseLayout>
  );
};

export default UploadDocumentPage;
