import * as Styles from './Title.styles';

const Title = ({ title }) => {
  return (
    <Styles.BackgroundIMG>
      <Styles.TitleContent>
        <Styles.Title>{title}</Styles.Title>
      </Styles.TitleContent>
    </Styles.BackgroundIMG>
  );
};

export default Title;
