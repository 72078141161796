import { useParams } from 'react-router';
import Title from './components/Title';
import Table from './components/Table';
import BaseLayout from 'layouts/baseLayout';

const TravelCompliancePage = () => {
  const {id: params} = useParams();

  return (
    <BaseLayout>
      <Title title="Business Travel Compliance" subTitle="Download your downstream compliance files from Vialto" />
      <Table params={params} />
    </BaseLayout>
  );
};

export default TravelCompliancePage;
