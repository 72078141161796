import { TimePicker, DatePicker } from 'antd';
import moment from 'moment';
import FormBuilder from 'antd-form-builder';

const timeformat = 'HH:mm';
const formatDate_ = 'YYYY-MM-DD';

FormBuilder.defineWidget('time-picker', TimePicker, (field) => {

  let widgetDefinition = {
    ...field,
    format: timeformat,
    onChange: (dates, dateStrings) => {
      dates.utc(true).set(moment.utc(dateStrings, 'HH-mm').toObject());
    }
  };
  if (field.initialValue) {
    widgetDefinition['initialValue'] = moment.utc(field.initialValue, timeformat).utc();
    widgetDefinition['locale'] = "utc";
  }

  return widgetDefinition;
});

FormBuilder.defineWidget('date2-picker', DatePicker, (field) => {
  let widgetDefinition = {
    ...field,
    format: formatDate_,
    locale: "utc",
    disabledTime: true,
    onChange: (dates, dateStrings) => {
      dates.utc(true).set(moment.utc(dateStrings).toObject());
    }
  };

  if (field.initialValue) {
    widgetDefinition['initialValue'] = moment.utc(field.initialValue, formatDate_)
  }

  return widgetDefinition;
});
