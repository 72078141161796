import React, { Component } from 'react';
//import AuthIFrame from "react-auth-iframe";
import MyiFrame from '../../components/frame/MyFrame';
import { makeRequest } from '../../features/communication/request';
import { Container } from 'reactstrap';
import { message } from 'antd';
import 'antd/dist/antd.css';
import './kibana.css';

class MyForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      kibanaDashboards: null,
      kibanaReporter: null,
      src: '',
    };
    this.formRef = React.createRef();
  }

  checkRole(data) {
   
    if (data.roles) {
      if (data.roles.includes('Admin') || data.roles.includes('Report')) {
        return true;
      }
    }
    if (data.context && (data.context == 'Admin' || data.context == 'Report')) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    makeRequest({
      method: 'get',
      url: 'checkReporter',
    })
      .then((resp) => {
        if (this.checkRole(resp.data)) {
          let kibana_src;
          if (resp.data.dashboardUrl) {
            kibana_src = resp.data.dashboardUrl.split('#');
          } else {
            kibana_src = process.env.REACT_APP_KIBANA_DASHBOARD.split('#');
          }
          if (kibana_src[0].includes('?')) {
            kibana_src[0] += '&';
          } else {
            kibana_src[0] += '?';
          }
          kibana_src[0] += 'auth=' + resp.data.token;
          this.setState({
            kibanaReporter: true,
            src: kibana_src.join('#'),
          });
        } else {
          message.info("You don't have permission to show reports");
          this.setState({ kibanaReporter: false });
        }
      })
      .catch((err) => err);
  }

  
  render() {
    return (
      <Container fluid="true" className="screen-container">
        {this.state.kibanaReporter ? (
          <iframe title="reporting" src={this.state.src} height="800" width="100%"></iframe>
        ) : null}
        <div className="footer">
          <div className="vialto"></div>
          <div className="footer-copyrights">©2023 Vialto All rights reserved</div>
        </div>
      </Container>
    );
  }
}

export default MyForms;
