import React,{forwardRef,useRef,useEffect} from 'react'
import { useTable,useGlobalFilter,usePagination, useSortBy } from 'react-table';
import { Input, Button, Popover,Select,Spin } from 'antd';
import AdminConfig from './adminConfig';
import {
  SortAscendingOutlined,
  SortDescendingOutlined
 
} from '@ant-design/icons';
import ReactPaginate from 'react-paginate';
const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;
  
    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
  
    return (
      <div class="cb action">
        <label>
          <input type="checkbox" className='checkbox_cb_action' ref={resolvedRef} {...rest} />
          <span>All</span>
        </label>
      </div>
    );
  });


export default function Table({data,loading}) {
    const { columns, HideColumns,perPageItems } = AdminConfig();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    
        prepareRow,
        state,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
      } = useTable({
        columns,
        data,
        initialState: {
          hiddenColumns: HideColumns,
          pageSize:10,
        },
      },useGlobalFilter,useSortBy,usePagination);

      const {globalFilter,pageIndex, pageSize} = state

    

  return (
  <>
  <div className='admin_filter_button'>
  
  <Input allowClear type='text' style={{width:'226%'}} placeholder='Search within results' onChange={(e) => setGlobalFilter(e.target.value) } value={globalFilter} className='serach_MR'  />
  
<Input.Group compact>
  <Popover
    content={
      <div>
        <div>
          <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
        </div>
        {/* Loop through columns data to create checkbox */}
        {allColumns.map((column) => (
          <div class="cb action" key={column.id}>
            <label>
              <input type="checkbox"  className='checkbox_cb_action' {...column.getToggleHiddenProps()} />{' '}
              <span>{column.Header}</span>
            </label>
          </div>
        ))}
        <br />
      </div>
    }
    trigger="click"
    placement="bottomLeft"
  >
    <Button type='primary'>Columns</Button>
  </Popover>
</Input.Group>
</div>

   <div className='admin_table_wrapper'>
    
   <table {...getTableProps()} className="table table-striped">
     <thead>
       {headerGroups.map((headerGroup) => (
         <tr {...headerGroup.getHeaderGroupProps()}>
           {headerGroup.headers.map((column) => (
             <th {...column.getHeaderProps(column.getSortByToggleProps())}>
              {column.render('Header')}
              <span>
                  {column.isSorted ? (column.isSortedDesc ? <SortAscendingOutlined style={{fontSize:'20px',color:'#1CDBBC'}} /> :  <SortDescendingOutlined style={{fontSize:'20px',color:'#1CDBBC'}}/>) : ""}
                </span>
              </th>
           ))}
         </tr>
       ))}
     </thead>
     <tbody {...getTableBodyProps()}>
     { page && page.length > 0 ? page && page.map((row, i) => {
         prepareRow(row);
         return (
           <tr {...row.getRowProps()}>
             {row.cells.map((cell) => {
               return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
             })}
           </tr>
           
         );
       }) : <tr ><td colspan='15' className='admin_table_item_align'>{loading ? <Spin/> : ' No Records Available'}</td></tr>}
     {page && page.length > 0 && loading && <div className='spin_table'><Spin/> </div>}
     </tbody>
   </table>
   
 </div>
 <div className="pagination_wrapper">
            {pageOptions && pageOptions.length > 0 && <div className="pagination_select">
              <Select
                value={pageSize}
                className="treeselect"
                placeholder="Please select"
                onChange={(newval) => setPageSize(newval)}
                options={perPageItems.map(({ id, value }) => ({ label: id, value: value }))}
              />
            </div>}
            <div className="react_paginator">
            <ReactPaginate
                breakLabel="..."
                forcePage = {pageIndex}
                nextLabel="next >"
                onPageChange={(e) => gotoPage(e.selected)}
                pageRangeDisplayed={5}
                pageCount={pageOptions.length}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                containerClassName={'pagination'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
                disabledClassName={'disabled'}
              />
            </div>
          </div>
       
     
        

</>
  )
}
