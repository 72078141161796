import styled from 'styled-components';
import { Input } from 'antd';

const InputWithLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.p`
  font-weight: bold;
  font-size: 11px;
  margin: 2px;
  text-align: left;
  font-family: 'Aeonik-Regular';
`;

const InputFilter = ({ label, error,type, state, setState, disabled , removeError}) => {
  const handleChange = (event) => setState(event.target.value);
  

  return (
    <InputWithLabelWrapper>
      <Label className={(label !== 'Record Locator' && label !== 'MSW Countries') ? 'required' : ''}>{label}</Label>
      <Input type={type} value={state} onChange={handleChange} onKeyUp={() => removeError(label)} disabled={disabled} />
      {error && error.map((field) => {
        if(field.error === label){
          return <p className='error_msg_uploadgfile'>{field.message}</p>
        }
      })}
    </InputWithLabelWrapper>
  );
};

export default InputFilter;