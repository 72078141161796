import * as Styles from './Title.styles';

const Title = ({ title, subTitle }) => {
  return (
    <Styles.BackgroundIMG>
      <Styles.TitleContent>
        <Styles.Title>{title}</Styles.Title>
        <Styles.SubTitle>{subTitle}</Styles.SubTitle>
      </Styles.TitleContent>
    </Styles.BackgroundIMG>
  );
};

export default Title;
