export default class AppVersion{
    constructor(version) {
        let versionParts = version.split(".")
        this.major = parseInt(versionParts[0]) || 0
        this.minor = parseInt(versionParts[1]) || 0
        this.patch = parseInt(versionParts[2]) || 0
    }
    
    isNewerThan(versionToCompare){
        if(this.major > versionToCompare.major){
            return true
        }else if (this.major < versionToCompare.major){
            return false
        }
        if(this.minor > versionToCompare.minor){
            return true
        }else if (this.minor < versionToCompare.minor){
            return false
        }
        return this.patch > versionToCompare.patch
    }
    
}
