import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { List, WindowScroller } from 'react-virtualized';
import { Container, Row, Col } from 'reactstrap'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Spin } from 'antd';
import './events.css';

const Events = () => {

    const [ events, setEvents ] = useState(null);
    const [ rowsRendered, setRowsRendered] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${process.env.REACT_APP_EVENT}`);
            setEvents(response.data)
        }
        fetchData();
    }, []);

    const rowRenderer = ({ key, index, style}) => {
        return (
                <Row key={key} style={style}>
                    <Col>{events[index]._id}</Col>
                    <Col>{events[index].IsDuplicateTrip ? <CheckOutlined /> : <CloseOutlined />}</Col>
                    <Col>{formatDate(events[index].TripCancelledOn)}</Col>
                    <Col>{formatDate(events[index].TripCreatedOn)}</Col>
                    <Col>{formatDate(events[index].TripUpdatedOn)}</Col>
                    <Col>{events[index].RecordLocator}</Col>

                </Row>
          );
    }

    const formatDate = (date) => moment(new Date(Date.parse(date))).format('MMMM Do YYYY')

    return (
        <Container className="container">
                <Row className="table-header">
                        <Col>Id</Col>
                        <Col>Is Duplicate Trip</Col>
                        <Col>Trip Cancelled On</Col>
                        <Col>Trip Created On</Col>
                        <Col>Trip Updated On</Col>
                        <Col>Record Locator</Col>
                </Row>
            { !rowsRendered && <div className="p-100"><Spin size="large"/></div> }
            { events && (
                <WindowScroller>
                  {({ height, scrollTop  }) => (
                    <List
                    autoHeight
                    width={1200}
                    height={height}
                    rowCount={events.length}
                    rowHeight={70}
                    rowRenderer={rowRenderer}
                    scrollTop={scrollTop}
                    onRowsRendered={() => setRowsRendered(true)}
                    />)}
                </WindowScroller>
            )}
        </Container>
    );
}

export default Events;