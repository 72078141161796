import Axios from 'axios';
import React, {Component} from "react";
import axios from "axios";

class MyiFrame extends Component {
    constructor(props) {
        super(props)
        this.iframeRef = React.createRef();
        this.iframeRef2 = React.createRef();
        this.state = {
            finish: false
        }
    }

    fetchData = () => {

        const token = 'Bearer ' + sessionStorage.getItem('token');

        Axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
        Axios.defaults.xsrfCookieName = 'csrftoken';

        axios({
            url: this.props.url,
            method: 'GET',
            headers: {
                Authorization: token,
                accept: "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
               
                "Upgrade-Insecure-Requests": 1,
                
           },
       }).then((response) => {
            
            const frame2 = this.iframeRef2.current;
           
            let doc = frame2.contentDocument
           
            frame2.src = this.props.url;
            
       }).catch(e => console.error("ERROR: ", e));

   }

   componentDidMount() {
       this.fetchData();
   }
   
    render () {
        const { src, token, ...props } = this.props

        return (
            <div>
                <iframe title="Dashboard" ref={this.iframeRef2} src='about:blank' width="100%" height="800px" allow="sync-xhr 'self';" />
            </div>
        )

    }
}

export default MyiFrame;
