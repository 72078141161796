import React, { Component } from 'react';

let timer=null
class FirstMessage extends Component {
  
    constructor(props) {
      super(props);
      clearTimeout(timer)
      timer = setTimeout(() => {
      this.props.triggerNextStep({  trigger: 'timeout' });
                                    
                                     }, 600000); 
  
      this.props.triggerNextStep({  trigger: 'message2bot' });
    }

  render() {
          return <div> Hello {this.props.firstName}, It is great to meet you. I am your Global Mobility Services (GMS) assistant. I can answer your immediate compliance questions about immigration, tax, or social security. I can also answer immediate technology questions about myTrips. Finally, I can put you in touch with a human specialist who will respond when they are available.
          </div>
          }

}




export default FirstMessage;
