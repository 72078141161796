import styled from 'styled-components';
import BackgroundImage from 'assets/img/header-bg.jpg';


export const Title = styled.div`
  color: white;
  font-size: 24px;
  margin: 15px 0;
  padding: 2px 15px;
  font-family: 'Aeonik-Bold';
  font-size: 32px;
`;

export const SubTitle = styled.div`
  color: white;
  /* width: 100%; */
  font-size: 24px;
  margin: 15px 0;
  padding: 2px 15px;
  font-family: 'Aeonik-Medium';
`;

export const TitleContent = styled.div`
  min-width: 50%;
  display: flex;
flex-direction:column;
justify-content: center;
align-items: center;
`;

export const BackgroundIMG = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35vh;
  background: transparent no-repeat;
  background-image: linear-gradient(to top,rgb(43 29 29 / 46%),rgb(0 0 0 / 59%)), url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  
`;
