
import { createContext, useCallback, useState } from 'react';

export const BaseLayoutContext = createContext({});

const BaseLayoutProvider = ({ children }) => {
  const [showSettingsButton, setShowSettingsButton] = useState(false);
  const [isSettingsWindowOpen, setIsSettingsWindowOpen] = useState(false);
  const [userRole,setUserRole] = useState()

  
  const changeSettingsWindowVisibility = useCallback(
    () => setIsSettingsWindowOpen((prevState) => !prevState),
    []
  );

  const contextValue = {
    showSettingsButton,
    isSettingsWindowOpen,
    setShowSettingsButton,
    changeSettingsWindowVisibility,
    userRole,
    setUserRole
  };

  return <BaseLayoutContext.Provider value={contextValue}>{children}</BaseLayoutContext.Provider>;
};

export default BaseLayoutProvider;
