import React, { Component } from 'react';
import api from '../../../services/api';
import PropTypes from 'prop-types';

let timer=null
class RasaBot extends Component {
  constructor(props) {
    super(props);
    
    this.props.triggerNextStep({  trigger: 'message2bot' });
    }
  componentWillMount() {
    let message;
    const { steps } = this.props;
    const message2bot = steps.message2bot.value;
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.props.triggerNextStep({  trigger: 'timeout' });
      
    }, 600000); // Production update - min 10 minut

    api({ sender: localStorage.getItem('sender'), message: message2bot })
      .then((resp) => {
        message = resp[0].text ? JSONcon(resp) : 'no data coming...';
        this.setState({ result: message });
      })
      .catch(() => {
        message = 'error';
      });

    this.setState({ loading: false, result: message });

    const JSONcon = (json) => {
      var concatenated = '';
      var i = 0;
      do {
        try {
          concatenated = concatenated + '\n' + json[i].text;
          i += 1;
        } catch {
          break;
        }
      } while (true);
      return concatenated.trim();
    };
  }

  render() {
    const { result } = this.state;
    return <div style={{ 'white-space': 'pre-line' }}> {result} </div>;
  }
}


RasaBot.propTypes = {
  steps: PropTypes.object,
};

RasaBot.defaultProps = {
  steps: undefined,
};

export default RasaBot;
