import { combineReducers } from 'redux';
import auth from './auth';
import example from './example';
import domain from './domain';

export default combineReducers({
  auth,
  example,
  domain,
});
