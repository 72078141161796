import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BaseLayout from '../layouts/baseLayout/baseLayout';


class MainPage extends Component{
state ={
  windowOpened:false,


};
/*  HandleMove() {  
    clearTimeout(this.state.timer);
     this.setState({timer: setTimeout(()=>{
       if(this.state.windowOpened==false)
       {
         window.open('/form/test','' , 'width =1000px,height=500px');
         this.setState({windowOpened:true})
        }
      }
        ,10000 )
  })}*/


  render(){  
 return (
   <BaseLayout>
       <div onMouseMove={this.HandleMove} style={{padding: '40px'}}>
         <p className='landing-text'>Welcome to Vialto Partners downstream business travel compliance technology. <br></br>
         To complete travel questionnaire, use the full website link previously provided. <br></br>
         Please contact your Vialto Partners engagement team with any questions.<br></br>
         For more information on Vialto Partners travel compliance solutions click <a href="https://vialto.com/" target="_blank" style={{display: 'contents', color: '#0A838A'}}>here</a></p>
       </div>
   </BaseLayout>   

  );
}}

export default MainPage;
