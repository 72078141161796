
const endpoint = process.env.REACT_APP_CHATBOT_WEBHOOKS


export default async function api(body) {
  try {
    // let api = endpoint;
    // const response = await fetch(api, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) });
   
    // return response.json();
    return "";
  } catch (error) {
    throw error;
  }
}
